import React from "react";
import Card from "react-bootstrap/Card";
import { AiOutlineArrowRight } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0" data-aos="zoom-in">
          <p style={{ textAlign: "justify" }}>
            Greetings! <span className="primary-header">Shankar Kharel</span>{" "}
            from Butwal,Nepal. I'm currently pursuing my bachelors degree
            in Software Engineering and am truly passionate about the world of
            programming. It's not just a field of study or a career path for me,
            but a source of boundless inspiration. I thrive on the thrill of
            solving complex problems and creating innovative solutions. Whether
            it's coding, developing software, or embarking on exciting
            programming adventures, I'm wholeheartedly dedicated to the world of
            technology.

            <br />
            In addition to coding, here are some other things I enjoy doing!
          </p>
          <ul>
            <li className="about-activity">
              <AiOutlineArrowRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Reading Books
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Playing Games
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Traveling
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
