import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import useScrollAnimation from "./useScrollAnimation"; // Import the custom hook

import "./home2.css"; // Import your CSS file here
function Home2() {
  const isAvatarVisible = useScrollAnimation(".avatar-trigger");

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }} data-aos="fade-right">
              <span className="primary-header"> ABOUT </span> ME
            </h1>
            <p className="home-about-body" data-aos="fade-up">
              <br />
              I'm a{" "}
              <i className="primary-header">
                Self-Taught Mobile Application Developer
              </i>{" "}
              who loves solving problems and building Solutions that matters.
              <br />
              <br />I am passionate about{" "}
              <i className="primary-header">technologies</i> and love to learn
              new things.
              <br />
              <br />I am more interested in Solving Problem through code
              <i>
                <i className="primary-header">
                  {" "}
                  Web Technologies and Application Development.
                </i>
              </i>
              <br />
              <br />I always apply my experience in developing products with{" "}
              <i className="primary-header"></i>frameworks like
              <i className="primary-header">
                {" "}
                flutter, Node, React and Django{" "}
              </i>
              <br />
              <br />
              Currently I am working as Mobile application{" "}
              <i className="primary-header">
                Developer, Instructor and trainer{" "}
              </i>
            </p>
          </Col>

          <Col md={4} className="myAvtar">
            <div
              className={`avatar-trigger ${isAvatarVisible ? "active" : ""}`}
            >
              <Tilt>
                <img
                  data-aos="fade-left"
                  src={myImg}
                  className="img-fluid"
                  alt="avatar"
                />
              </Tilt>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1 data-aos="fade-right">
              <span className="primary-header">CONNECT </span> WITH ME
            </h1>
            <p data-aos="fade-left">Feel free to connect with me</p>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="https://github.com/shankarkharel"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="github"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/shankarkharel19"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="twitter"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:itsshankar35@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/shankar-kharel-162846168/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="linkedin"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/shankarkharel07"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
