import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import PrivateChat from "../../Assets/Projects/makeFriiend.png";
import Khata from "../../Assets/Projects/pic5.png";
import Calculator from "../../Assets/Projects/calculator.png";
import SydneyHotel from "../../Assets/Projects/hotelbooking.png";
import WorldClock from "../../Assets/Projects/worldClock.png";
import PetZone from "../../Assets/Projects/petZone.jpg";
import Ecommerce from "../../Assets/Projects/e-commerce.png";
import Smartattendance from "../../Assets/Projects/smartattendance.png";
import SayatriDriver from "../../Assets/Projects/sayatri_driver.png";
import Sayatri from "../../Assets/Projects/sayatri.png";
import Appointment from "../../Assets/Projects/appintment.png";
import a2z_online from "../../Assets/Projects/a2z_online.png";
import xuoot_store from "../../Assets/Projects/xuoot_store_app.png";
import kagazpatra from "../../Assets/Projects/kagazpatra.png";

//import ExpenseTracker from "../../Assets/Projects/ExpenseTracker.png";

import "./project.css";

function Projects() {
  const teamMembers = [
    { name: "John Doe", github: "https://github.com/johndoe" },
    { name: "Jane Smith", github: "https://github.com/janesmith" },
  ];

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="Fluorescent-Blue">Works </strong>
        </h1>
        <p>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={SayatriDriver}
              title="Sayatri Driver"
              description="A driver app in a ride-sharing service enables drivers to accept ride requests, navigate to pickup and drop-off locations, and manage their ride history and earnings. The app typically includes features like incoming ride notifications, GPS-based navigation, fare calculation, and a dashboard displaying trip and income summaries..I Worked On this app When in sayatri Technologies Private Limited"
              // ghLink="https://github.com/shankarkharel/Simple-Calculator"
              demoLink="https://play.google.com/store/apps/details?id=com.tukisoft.sayatridriver&hl=en"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Sayatri}
              title="Sayatri User"
              description="A ride-sharing user app allows users to book rides, view nearby drivers, track their ride in real-time, and make payments. The app typically includes features like ride requests, estimated fare calculation, driver and vehicle details, route navigation, and a rating system for drivers and passengers.
               It provides a convenient and efficient way for users to commute by connecting them with available drivers nearby.
               I Worked On this app When in sayatri Technologies Private Limited"
              ghLink=""
              demoLink="https://play.google.com/store/apps/details?id=com.app.sayatri&hl=en"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={a2z_online}
              title="A2Z Online Institute"
              description="a software platform designed to deliver, track, and manage educational courses, training programs, or learning and development programs. It allows educators and trainers to create and organize content, assessments, and learning materials, while providing learners with access to courses, quizzes, and resources.I worked On this Project When I was Working At Tuki Soft Private Limited "
              features="Key features of an LMS include user management, progress tracking, communication tools, and reporting. It can be used in various settings, including schools, universities, corporate training, and online education, enabling efficient and scalable learning experiences."
              ghLink=""
              demoLink="https://play.google.com/store/apps/details?id=com.app.a2z&hl=en"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={xuoot_store}
              title="Xuoot Store App"
              description="A store app allows users to browse, search, and purchase products or services directly from their mobile devices. It typically includes features like product catalogs, search functionality, detailed product pages with images and descriptions, a shopping cart, and secure checkout options. Users can create accounts, track orders, 
              view order history, and receive notifications about promotions and new arrivals. The app may also offer payment integration, customer reviews, and personalized recommendations based on user preferences and browsing history, providing a seamless shopping experience.I Worked On this app When in DÈS VU Technologies Private Limited"
              features="Soft Buttons: The calculator buttons have a soft, recessed appearance with a subtle shadow, giving them a tactile and clickable feel.I Worked On this app When in DÈS VU Technologies Private Limited"
              ghLink=""
              demoLink="https://play.google.com/store/apps/details?id=com.xuoot.marketplace.seller&hl=en"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={kagazpatra}
              title="KagazPatra:Online Governmental Referal and Agreement Papper Generator"
              description="KagazPatra is an online platform designed for generating governmental referral and agreement papers. It facilitates the creation and management of official documents, allowing users to easily generate, customize, and manage referrals and agreements in a secure and streamlined manner. This service is particularly useful for handling bureaucratic processes and legal documentation, ensuring that all papers are correctly formatted and compliant with relevant regulations.I worked On this Project When I was Working At DÈS VU Technologies Private Limited "
              ghLink=""
              demoLink="https://www.kagazpatra.com/"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Khata}
              title="Khata:The Expense Tracker"
              description="Khata: is a cutting-edge mobile app crafted with Flutter, harnessing the power of advanced technologies.Through a sleek and user-friendly interface, Khata provides a comprehensive toolset for tracking and analyzing financial transactions. With features that make budgeting, expense categorization, and financial insights a breeze, this app exemplifies the fusion of modern app development and financial management, ensuring that your financial health is always at your fingertips.I Worked On this as a part of My Personal Project"
              ghLink="https://github.com/shankarkharel/Khata-The-Expense-Tracker"
              demoLink=""
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={PrivateChat}
              title="Guff Gaff"
              description="A Native Android Chat Application Written in Java and Firebase as backend to share resources and hangout with friends build with Java Android, and Firebase. Have features which allows user for realtime messaging, image sharing and search user."
              ghLink="#"
              demoLink="https://github.com/shankarkharel/Guff-Gaf"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={PetZone}
              title="Pet Zone"
              description="
Pet Zone mobile app with PHP in backend and MySQL as Database is a dedicated application for pet lovers, pet owners, and those interested in pet-related activities and services. This app is designed to provide a comprehensive platform for pet-related information, services, and social interactions."
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={WorldClock}
              title="World Clock"
              description="A World Clock app using World Clock API is a utility application that provides users with the current time in various cities and time zones around the world. "
              ghLink="https://github.com/shankarkharel/World-Clock"
              demoLink=""
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Smartattendance}
              title="Smart Attendance"
              description={`Smarrt Attendance is a university project employing geofencing for location-based check-ins. It features dynamic QR code generation, manual entry, leave requests, and email alerts for low attendance. Notifications keep students and teachers updated, while insights offer summaries for attendance management.Project team Members: Niruta Poudel , Shankar Kharel And Bikram Parajuli`}
              ghLink="https://github.com/shankarkharel/smart_attendance_desktop"
              demoLink=""
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Appointment}
              title="Appointment App"
              description="Appointment app Written in Flutter ,Dar and PHP MYsql.It is designed to facilitate the scheduling, booking, and management of appointments or reservations for various purposes."
              demoLink=""
              ghLink="https://github.com/shankarkharel/appointment-app-mobile-doctor"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Ecommerce}
              title="Online Shop"
              description="E-commerce web app Written in HTML JS PHP and MYsql.A digital platform that enables users to browse, search, and purchase a wide range of products and services over the internet."
              ghLink="https://github.com/shankarkharel/e-commerce-website"
              demoLink=""
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={SydneyHotel}
              title="Sydney Hotel"
              description="
 Sydney Hotel ASP dot NET MVC web application that allows users to browse, search, and book hotel rooms for their travel or accommodation needs."
              ghLink="https://github.com/shankarkharel/sydney-hotel"
              demoLink="http://ww25.sydneyhotel.com.au/"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Calculator}
              title="Neumorphic Calculator"
              description="This is a simple calculator in neumorphic design in flutter and dart."
              features="Soft Buttons: The calculator buttons have a soft, recessed appearance with a subtle shadow, giving them a tactile and clickable feel."
              ghLink=""
              demoLink="https://github.com/shankarkharel/Simple-Calculator"
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;
