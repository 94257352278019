import { useState, useEffect } from "react";

function useScrollAnimation(targetSelector) {
  const [isTargetVisible, setIsTargetVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsTargetVisible(true);
        }
      });
    });

    const target = document.querySelector(targetSelector);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [targetSelector]);

  return isTargetVisible;
}

export default useScrollAnimation;
